<template>
  <v-row justify="center">
      <v-dialog v-model="show" scrollable max-width="500px" persistent>
        <v-card>
          <v-card-text style="height: 320px" class>
            <div class="ma-6 pa-0 text-center">
              <img
                src="@/assets/img/alertbell_mobile.png"
                width="100px"
                height="100px"
                alt="One Box"
              />
            </div>
            <div class="text-center" style="font-size: 13px;color:#333333">
              <h2>เรียนผู้ใช้งาน One Box ทุกท่าน</h2>
            </div>
            <div>
              <br />
              <span style="font-size: 16px;" class="ml-4">
                หากผู้ใช้งานไม่พบเอกสาร e-Tax Invoice รบกวนส่ง E-mail มาที่ Nockkc@inet.co.th 
              </span>   
              <br/>
              <span style="font-size: 16px;" class="ml-4 mt-1">
                เนื้อเรื่อง Resend เอกสารเข้าระบบ One Box ของบริษัท (ระบุชื่อบริษัท) และ Tax ID (ระบุ Tax ID ของบริษัท)
              </span>        
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="text-center ml-0">
            <!-- <v-checkbox
              v-model="checkshow"
              class="mx-0"
              label="ไม่ต้องการให้แสดงอีก"
            ></v-checkbox> -->
            <v-btn block color="#184966" class="white--text" style="font-size: 16px;" @click="checkdialog()"
              >ปิด</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
  </v-row>
</template>
<script>
import VueCookies from "vue-cookies";
export default {
  props: ["show"],
  data: function() {
    return {
      checkshow: false,
      dialog: false,
    }
  },
  methods:{
    checkdialog(){
      if (this.checkshow == false) {
        VueCookies.set('checkalertresend', false);
        this.$emit('closedialog')
      }else if(this.checkshow == true){
        VueCookies.set('checkalertresend', true);
        this.$emit('closedialog')
      }
    },
    
    
  }
}
</script>